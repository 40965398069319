
<ng-container *ngFor="let resourceAssignment of resourceAssignents">
    <app-order-rent-resourceAssignment-protocols-view
        [showReviewProtocol]="false"
        [resourceAssignment]="resourceAssignment">
    </app-order-rent-resourceAssignment-protocols-view>
</ng-container>

<div
    *ngIf="resourceAssignents"
    class="justify-end flex flex-row mt-4">
    <nx-default-button
        (click)="completeTask()"
        class="confirm"
        [tooltip]="'Geprüft'"
        [style]="'dark'"
        [label]="'Geprüft'">
    </nx-default-button>
</div>
