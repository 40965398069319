import { gql } from 'apollo-angular';
import { ResourceAssignment } from 'src/app/rent-module/shared/entities/resourceAssignment/resourceAssignment';

export const RENTAL_RESOURCE_ASSIGNMENT_REVIEW_QUERY = gql`
query getResourceAssignments(
  $ids: [UUID]
)
{
  resourceAssignments: getResourceAssignments(
    ids: $ids
  ){
free
        text
        protocols {
          id
          type
          state
          completedDateTime
          completedBy {
            firstName
            lastName
          }
          formControlTemplate
          task{
            id
            assignment {
              assigneeId
              assignmentType
              typeLabel
              name
            }
          }
        }
        startDateTime
        endDateTime
        customAttributes {
          id
          name
          value
          customAttributeDefinition {
            id
          }
          customAttributeSection {
            id
          }
        }
        resource {
          id
          text
          name
          number
          functions
        }
        effectiveSchedulingInformation{
          inHandlingType
          inDateTimePeriod {
            dateTime
            fromDateTime
            toDateTime
            modifier
          }
          inAddress {
            street
            houseNumber
            postalCode
            city
            country
            geocodingResultGrade
            name
            note
          }
          outHandlingType
          outDateTimePeriod {
            dateTime
            fromDateTime
            toDateTime
            modifier
          }
          outAddress{
            street
            houseNumber
            postalCode
            city
            country
            geocodingResultGrade
            name
            note
          }
          onSiteContacts {
            name
            firstName
            lastName
          }
          inLocation{
            name
          }
          outLocation{
            name
          }
        }
  }
}

`;


export interface RentalResourceAssignmentReviewQuery {
  resourceAssignments: ResourceAssignment[];
}


