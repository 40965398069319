import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { forkJoin, from, map, Observable } from 'rxjs';

export function initializeTranslate(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('de');
    return translate.use('de')
  };
}

export class MultiTranslateLoader implements TranslateLoader {
  constructor(private locations: string[]) {}

  getTranslation(lang: string): Observable<any> {
    // Load translations from all specified locations
    // we do not use the HttpClient to avoid circular dependencies
    const requests = this.locations.map(location =>
      from(fetch(`${location}/${lang}.json`).then(response => response.json()))
    );

    // Combine and merge the translation objects
    return forkJoin(requests).pipe(
      map(translationsArray =>
        translationsArray.reduce((acc, translations) => {
          return { ...acc, ...translations };
        }, {})
      )
    );
  }
}

/**
 * Since we have to load translations for the app and the core, we need to load
 * translations from multiple locations. For that we need to have a custom loader implementation.
 * @returns 
 */
export function createTranslateLoader() {
  return new MultiTranslateLoader([
    './assets/i18n/core', // First location
    './assets/i18n/app',  // Second location
  ]);
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  
  constructor() {
  }

  // currently nothing implemented here
  // we leave the class here for future use
  // whats used here are the factory and initializer functions above
}