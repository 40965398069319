
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApiKeyComponent, BillingComponent, CompanyViewComponent, DeveloperPageComponent, FormsListComponent, FormVariantComponent, GroupListComponent, GroupViewComponent, MobileDeviceListComponent, NoAccessPageComponent, TemplatesComponent, TemplateVariantComponent, TenantListComponent, TenantViewComponent, UserListComponent, UserProfileComponent, UserViewComponent } from '@nexato/nx-core-module';
import { AuthGuard } from './guard/auth.guard';
import { PermissionGuard } from './guard/permission.guard';
import { OrderViewComponent } from './order-module/containers/order-view/order-view.component';
import { OrdersListComponent } from './order-module/containers/orders-list/orders-list.component';
import { SettingsComponent } from './rent-module/components/settings/settings.component';
import { TaskAssignmentComponent } from './rent-module/components/task-assignment-component/task-assignment.component';
import { ArticlesListComponent } from './rent-module/containers/articles-list/articles-list.component';
import { CustomerViewComponent } from './rent-module/containers/customer-view/customer-view.component';
import { CustomersListComponent } from './rent-module/containers/customers-list/customers-list.component';
import { DashboardComponent } from './rent-module/containers/dashboard/dashboard.component';
import { GeneralSettingsComponent } from './rent-module/containers/general-settings/general-settings.component';
import { LocationViewComponent } from './rent-module/containers/location-view/location-view.component';
import { LocationsListComponent } from './rent-module/containers/locations-list/locations-list.component';
import { TasksComponent } from './rent-module/containers/my-tasks/my-tasks.component';
import { ResourceViewComponent } from './rent-module/containers/resource-view/resource-view.component';
import { ResourcesListComponent } from './rent-module/containers/resources-list/resources-list.component';
import { SettingsOverviewComponent } from './rent-module/containers/settings-overview/settings-overview.component';
import { LayoutComponent } from './shared-module/components/layout/layout.component';
import { NonLicensePageComponent } from './shared-module/components/non-license-page/non-license-page.component';
import { ToursDetailPageComponent } from './tour-planning/containers/tours-detail-page/tours-detail-page.component';
import { ToursPlannerPageComponent } from './tour-planning/containers/tours-planner-page/tours-planner-page.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_dashboard_view' }
      },
      {
        path: 'tour-planner',
        component: ToursPlannerPageComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_tour_view'}
      },
      {
        path: 'tour-planner/detail/:id',
        component: ToursDetailPageComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_tour_view'}
      },
      {
        path: 'orders',
        component: OrdersListComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_order_view' },
      },
      {
        path: 'orders/order/:id',
        component: OrderViewComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_order_view' },
      },
      {
        path: 'customers', component: CustomersListComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_contact_view' },
      },
      {
        path: 'customers/customer/:id',
        component: CustomerViewComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_contact_view' },
      },
      {
        path: 'resources', component: ResourcesListComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_resource_view' },
      },
      {
        path: 'resources/resource/:id',
        component: ResourceViewComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_resource_view' },
      },
      {
        path: 'articles', component: ArticlesListComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_article_view' },
      },
      { path: 'assignment',
        component: TaskAssignmentComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_scheduling_view' }
      },
      { path: 'tasks',
        component: TasksComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'nexcore_scheduling_view' }
      },
      { path: 'profile',
        component: UserProfileComponent
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          {
            path: 'overview',
            component: SettingsOverviewComponent
          },
          {
            path: 'users',
            component: UserListComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_users_manage' },
          },
          {
            path: 'users/user/:id',
            component: UserViewComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_users_manage' },
          },
          {
            path: 'groups',
            component: GroupListComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_groups_manage' },
          },
          {
            path: 'groups/group/:id',
            component: GroupViewComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_groups_manage' },
          },
          {
            path: 'tenants',
            component: TenantListComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_tenants_manage' },
          },
          {
            path: 'tenants/tenant/:id',
            component: TenantViewComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_tenants_manage' },
          },
          {
            path: 'mobileDevices',
            component: MobileDeviceListComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_mobileDevice_view' },
          },
          {
            path: 'company',
            component: CompanyViewComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_tenants_manage_own' },
          },
          {
            path: 'templates',
            component: TemplatesComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_templates_manage' },
          },
          {
            path: 'templates/:templateKey/:id',
            component: TemplateVariantComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_templates_manage' },
          },
          {
            path: 'developer',
            component: DeveloperPageComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_developer_view' },
          },
          {
            path: 'developer/api-key/:id',
            component: ApiKeyComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_developer_view' },
          },
          {
            path: 'locations',
            component: LocationsListComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'nexcore_location_view' },
          },
          {
            path: 'locations/location/:id',
            component: LocationViewComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'nexcore_location_view' },
          },
          {
            path: 'general',
            component: GeneralSettingsComponent,
            canActivate: [PermissionGuard],
          },
          {
            path: 'forms',
            component: FormsListComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_forms_manage' },
          },
          {
            path: 'forms/:formKey/:id',
            component: FormVariantComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_forms_manage' },
          },
          {
            path: 'billing',
            component: BillingComponent,
            canActivate: [PermissionGuard],
            data: { permission: 'framework_licence_view' },
          },
          { path: '', redirectTo: 'overview', pathMatch: 'full' },],
      },
      { path: 'app-no-access-page', component: NoAccessPageComponent },
      { path: 'no-license', component: NonLicensePageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: '' },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
