import { gql } from 'apollo-angular';
import { Tour } from 'src/app/tour-planning/shared/entities/tour';


export const TOUR_QUERY = gql`
query getTour(
  $id: UUID
)
{
  tour: getTour(
    id: $id
  ){
    id
    resource {
      id
      name
      number
    }
  }
}
`;


export interface TourQuery {
  tour: Tour;
}


