import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule, EditDialogService, SettingsService, ToastService } from '@nexato/nx-core-module';
import { LightgalleryModule } from 'lightgallery/angular';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { FormService } from '../tour-planning/shared/services/form/form.service';

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AceModule } from 'ngx-ace-wrapper';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { OrderModule } from '../order-module/order.module';
import { AddressAutocompleteComponent } from '../shared-module/components/address-autocomplete/address-autocomplete.component';
import { AddressDialogComponent } from '../shared-module/components/address-dialog/address-dialog.component';
import { AddressInputControlComponent } from '../shared-module/components/adresses-form/addresses-form.component';
import { AssignmentDialogComponent } from './components/assignment-dialog/assignment-dialog.component';
import { ContactPersonDialogComponent } from './components/contact-person-dialog/contact-person-dialog.component';
import { ResourceDialogComponent } from './components/resource-dialog-component/resource-dialog.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaskAssignmentComponent } from './components/task-assignment-component/task-assignment.component';
import { TaskAssignmentListAssignedComponent } from './components/task-assignment-list-assigned/task-assignment-list-assigned.component';
import { TaskAssignmentListCompletedComponent } from './components/task-assignment-list-completed/task-assignment-list-completed.component';
import { TaskAssignmentListUnassignedComponent } from './components/task-assignment-list-unassigned/task-assignment-list-unassigned.component';
import { ArticlesListComponent } from './containers/articles-list/articles-list.component';
import { CompleteTaskViewComponent } from './containers/complete-task-view/complete-task-view.component';
import { ContactPersonListComponent } from './containers/contact-person-list/contact-person-list.component';
import { RentalResourceAssignmentReviewFormComponent } from './containers/custom-forms/rental-resource-assignment-review-form/rental-resource-assignment-review-form.component';
import { CustomerViewComponent } from './containers/customer-view/customer-view.component';
import { CustomersListComponent } from './containers/customers-list/customers-list.component';
import { DashboardTaskListComponent } from './containers/dashboard-task-list/dashboard-task-list.component';
import { DashboardTourListComponent } from './containers/dashboard-tour-list/dashboard-tour-list.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { GeneralSettingsComponent } from './containers/general-settings/general-settings.component';
import { LocationDialogComponent } from './containers/location-dialog/location-dialog.component';
import { LocationViewComponent } from './containers/location-view/location-view.component';
import { LocationsListComponent } from './containers/locations-list/locations-list.component';
import { TasksComponent } from './containers/my-tasks/my-tasks.component';
import { ResourceViewComponent } from './containers/resource-view/resource-view.component';
import { ResourcesListComponent } from './containers/resources-list/resources-list.component';
import { SettingsOverviewBoxComponent } from './containers/settings-overview-box/settings-overview-box.component';
import { SettingsOverviewComponent } from './containers/settings-overview/settings-overview.component';
import { ContactPersonService } from './shared/services/contactPerson/contactPerson.service';
import { GoogleService } from './shared/services/google.service';
import { HereService } from './shared/services/here.service';
import { LocationService } from './shared/services/location/location.service';
import { ResourceService } from './shared/services/resource/resource.service';
import { TasksService } from './shared/services/tasks/tasks.service';
@NgModule({
  declarations: [
    SettingsComponent,
    DashboardComponent,
    CustomersListComponent,
    ResourcesListComponent,
    ArticlesListComponent,
    LocationsListComponent,
    LocationViewComponent,
    LocationDialogComponent,
    ResourceViewComponent,
    ContactPersonListComponent,
    CustomerViewComponent,
    ResourceDialogComponent,
    TaskAssignmentComponent,
    GeneralSettingsComponent,
    DashboardTourListComponent,
    DashboardTaskListComponent,
    ContactPersonDialogComponent,
    AddressDialogComponent,
    TaskAssignmentListUnassignedComponent,
    TaskAssignmentListAssignedComponent,
    TaskAssignmentListCompletedComponent,
    AddressAutocompleteComponent,
    AddressInputControlComponent,
    SettingsOverviewComponent,
    SettingsOverviewBoxComponent,
    AssignmentDialogComponent,
    TasksComponent,
    CompleteTaskViewComponent,
    RentalResourceAssignmentReviewFormComponent
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    CoreModuleModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    LightgalleryModule,
    OrderModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    AceModule,
    MultiSelectModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    OverlayModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MatSnackBarModule,
    AutoCompleteModule,
    TooltipModule,
    PaginatorModule,
    TagModule
  ],
  exports: [
  ],
  providers:[
    LocationService,
    DatePipe,
    TasksService,
    ResourceService,
    FormService,
    SettingsService,
    ContactPersonService,
    HereService,
    GoogleService,
    DialogService,
    EditDialogService,
    ToastService
  ]
})
export class RentModule { }
