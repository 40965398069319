<mat-sidenav-container fullscreen class="sidenav-container" autosize>
  <mat-sidenav mode="side" opened [ngClass]="isExpanded? 'w-[258px]': ''">
    
    <div class="flex flex-row h-full">
      <div class="flex flex-col h-full bg-white"
        [ngClass]="isExpanded? 'w-[250px]': ''">
        <!-- side nav header -->
        <div class="h-[4.625rem] ml-[1rem] pt-[1.25rem]">
          <div class="details content-center flex flex-row">

            <div class="w-[2.125rem]">
              <img src={{icon}} alt="Logo" class="logo h-[24px]">
            </div>

            <div class="basis-3/5 font-medium flex flex-col justify-center overflow-hidden" *ngIf="isExpanded">
              <div
                *ngIf="pageTitle"
                class="text-base overflow-hidden text-ellipsis whitespace-nowrap">
                {{pageTitle}}
              </div>
            </div>

          </div>
        </div>
        <!-- side nav header -->

        <!-- side nav body -->
        <div class="sidenav_body grow">
          <app-navigation [isExpanded]="isExpanded"/>
        </div>
        <!-- side nav body -->

        <div class="spacer"></div>

        <!-- side nav footer -->
        <div class="sidenav_footer flex flex-col justify-center items-center mb-6" *ngIf="isExpanded">
          <img src={{this.logo}} alt="Logo" class="logo h-[5rem]">
          <div
            *ngIf="tenantId && rolesService.hasRole('framework_tenants_switch')"
            class="text-[#848488] text-sm font-light leading-3 absolute bottom-[6px]" >{{tenantId}} (v. {{versionNumber}})</div>
        </div>
        
        <!-- side nav footer -->
      </div>
      <div class="w-[12px] bg-[#F9F9F9] border-l border-gray justify-center items-center flex">
        <div class="expandHandle bg-[#d4d4d4] hover:bg-[#b7b5b5] m-1 h-[22px] w-[4px] rounded-md cursor-pointer"
          (click)="isExpanded = !isExpanded"
          [tooltipOptions]="tooltipOptions"
          [pTooltip]="isExpanded ? 'Menü reduzieren' : 'Menü erweitern'"></div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- <div class="fixed top-[0.5rem] left-[15.875rem] z-10 z-[999]"
      [ngClass]="{ 'left-[3.5rem]' : !isExpanded }">
      <i [className]="'icon icon-back-to'" (click)="isExpanded = !isExpanded" *ngIf="isExpanded" matTooltip="Menü reduzieren"></i>
      <i [className]="'icon icon-before'" (click)="isExpanded = !isExpanded" *ngIf="!isExpanded" matTooltip="Menü erweitern"></i>
    </div> -->
    
    <!-- toolbar -->
      <div class="sticky top-0 mx-auto px-[2.5rem] h-[5.125rem] bg-[#F9F9F9] z-50 flex max-w-7xl">

        <div class="flex-grow flex flex-row justify-start pt-[1.125rem] flex-wrap">
          <ng-container *ngIf="this.licenseService?.isLicenceUser()">
            <app-orders-search/>
          </ng-container>
        </div>

        <div class="grow flex flex-row justify-end pt-[0.875rem] flex-wrap">
          <nx-default-button
            [menuItems]="helpMeuItems"
            tooltip="Hilfe"
            class="pr-2 pt-1"
            [icon]="'icon-support-center'">
          </nx-default-button>
          <p-overlayPanel #op [style]="{width: '400px'}">
            <app-notification-center>
            </app-notification-center>
          </p-overlayPanel>
          <nx-default-button
            (click)="op.toggle($event)"
            [badgeValue]="(unreadNotifications$ | async)?.length"
            tooltip="Benachrichtigungen"
            class="pr-2 pt-1 h-[36px] notifications"
            [icon]="'icon-notification'">
          </nx-default-button>
          <nx-default-button
            [menuItems]="mainMenuItems"
            [menuWithIcon]="true"
            tooltip="Einstellungen"
            class="pt-1"
            [icon]="'icon-settings'">
            <ng-template nxTemplate="menuEndTemplate">
              <div class="pl-3 py-1 text-[#848488] font-light leading-3">
                {{givenName}} {{familyName}}
              </div>
            </ng-template>
        </nx-default-button>
        </div>

      </div>
    <!-- toolbar -->

    <div
      class="mx-auto pb-[22px] px-[2.5rem]"
      [ngClass]="[
        expandService?.getExpandState() ? 'max-w-full': 'max-w-7xl',
        isloading ? 'loading' : '',
        fullHeight ? 'full-height' : ''
        ]">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
