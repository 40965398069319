import { gql } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

export const COMPLETE_TASK_QUERY = gql`
query getTask(
  $id: UUID
)
{
  task: getTask(
    id: $id
  ){
    ... taskExcerpt
  }
}

fragment taskExcerpt on Task{
  id
  type
  dueDateTimePeriod {
    modifier
    dateTime
    fromDateTime
    toDateTime
  }
  state
  description
  color
  formControlTemplate
  context
  order{
    id
    number
    type
    customer {
      id
      type
      companyName
      companyNameExtension
      firstName
      lastName
    }
    location {
      name
    }
  }
}
`;

export interface CompleteTaskQuery {
  task: Task;
}

export const COMPLETE_TASK_MUTATION = gql`
mutation completeTask($taskId: UUID) {
  completeTask(taskId: $taskId){
    id
  }
}
`;

