import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Duration } from 'luxon';

// {
//   "__typename": "SettingDescription",
//   "key": "rent_rental_review_workflow_usertask_timeout_time_duration",
//   "label": "Timout für Aufgabe \"Prüfen\"",
//   "description": "Gibt ein Timeout für die Aufgabe \"Prüfen\" an. Wenn die Aufgabe nicht innerhalb der angegebenen Zeit abgeschlossen wird, wird die Aufgabe entfernt und der Prozess weitergeführt - nach ISO 8601 duration format.",
//   "type": "Duration",
//   "defaultValue": "P7D",
//   "currentValue": "P15D",
//   "settings": {
//       "scale": "days"
//   },
//   "validators": [
//       {
//           "settings": {
//               "min": "P1D"
//           },
//           "type": "min"
//       },
//       {
//           "settings": {
//               "max": "P30D"
//           },
//           "type": "max"
//       }
//   ]
// }

@Component({
  selector: "nx-duration-picker",
  templateUrl: "./nx-duration-picker.component.html",
  styleUrls: ["./nx-duration-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NxDurationPickerComponent),
      multi: true
    }
  ]
})
export class NxDurationPickerComponent implements ControlValueAccessor{

  public durationFormControl: FormControl;
  private _field: any;
  @Input() set field(field: any) {
    this._field = field;
    if(this._field) {
      this.buildFormControl();
    }
    console.log(this._field);
  };

  // public addressForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  getErrorMessage() {
    if (this.durationFormControl.errors) {
      const firstKey = Object.keys(this.durationFormControl.errors)[0];
      return this.durationFormControl.errors[firstKey];
    }
    return undefined;
  }

  buildFormControl() {
    const validators = [];
    validators.push(validISODurationStringValidator());
    this._field.validators.forEach((validator: any) => {
      if(validator.type === 'min') {
        validators.push(minDurationValidator(validator.settings.min));
      }
      if(validator.type === 'max') {
        validators.push(maxDurationValidator(validator.settings.max));
      }
    });
    
    this.durationFormControl = new FormControl(this._field.currentValue, validators);
    this.durationFormControl.valueChanges.subscribe(value => {
      if (this.durationFormControl.valid) {
        this.propagateChange(value);
      }
    });
  }


  /** value accessor */
  writeValue(value: any): void {
    // we initialize the form control with the current value that
    // should be sufficient to display the current value
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.durationFormControl?.disable();
    } else {
      this.durationFormControl?.enable();
    }
  }

  // buildOptions() {
  //   if(this._field) {
  //     // collect all types from the default values array
  //     const types = Object.keys(this._field.defaultValue);
  //     // iterate over all types and create an option for each
  //     this.options = [];
  //     this.colorsForm = new FormGroup({});
  //     types.forEach(type => {
  //       this.options.push({
  //         type: type,
  //         label: this._field.settings.labels[type],
  //         defaultColor: this._field.defaultValue[type],
  //         currentColor: this._field.currentValue[type]
  //       });
  //       let formControl = this.fb.control(this._field.currentValue[type]);
  //       this.colorsForm.addControl(type, formControl);
  //     });
  //     this.colorsForm.valueChanges.subscribe(value => {
  //       this.propagateChange(value);
  //     });
  //   }
  // }

  reset() {
    this.durationFormControl?.setValue(this._field.defaultValue);
  }
}

export function validISODurationStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // No value, no error
    }

    const current = Duration.fromISO(control.value);

    return current.isValid ? null : { invalidDuration: 'Ungültiges ISO 8601-Dauerformat.' };
  };
}

export function maxDurationValidator(maxDuration: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // No value, no error
    }

    const max = Duration.fromISO(maxDuration);
    const current = Duration.fromISO(control.value);

    // if (!current.isValid) {
    //   return { invalidDuration: 'Invalid ISO 8601 duration format' };
    // }

    return current.toMillis() <= max.toMillis() ? null : { maxDuration: `Dauer überschreitet das Maximum von ${maxDuration}.` };
  };
}

export function minDurationValidator(minDuration: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // No value, no error
    }

    const min = Duration.fromISO(minDuration);
    const current = Duration.fromISO(control.value);

    // if (!current.isValid) {
    //   return { invalidDuration: 'Invalid ISO 8601 duration format' };
    // }

    return min.toMillis() <= current.toMillis() ? null : { maxDuration: `Dauer überschreitet das Minimum von ${minDuration}.` };
  };
}