import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

export const TASK_LIST_QUERY = gql`
query TaskListQuery(
  $orderId: UUID,
  $states: [TaskState],
  $types: [String],
  $sortProperty: String,
  $sortDirection: String,
  $pageNumber: Int,
  $pageSize: Int
)
{
  tasks: getTasksPaged(
    taskFilter: {
      orderId: $orderId,
      states: $states,
      types: $types,
      sortRequest: {sortProperties: [$sortProperty], sortDirection: $sortDirection},
      pagination: { number: $pageNumber, size: $pageSize},
  }){
      totalPages
      totalElements
      number
      size
      content{
        id
        description
        type
        state
        handlingType
        color
        order{
          id
          number
          customer{
            type
            companyName
            firstName
            lastName
          }
        }
        dueDateTimePeriod {
          modifier
          dateTime
          fromDateTime
          toDateTime
        }
        tour {
          id
          name
          resource {
            name
          }
          driver {
            id
            firstName
            lastName
          }
          effectiveArrivalDateTime
          effectiveDepartureDateTime
        }
        completedBy {
          id
          firstName
          lastName
        }
        completedDateTime
        assignment {
          assigneeId
          assignmentType
          typeLabel
          name
          dueDateTimePeriod{
              modifier
              dateTime
              fromDateTime
              toDateTime
          }
        }
        assignmentSuggestion {
          assigneeId
          assignmentType
          typeLabel
          name
          dueDateTimePeriod{
              modifier
              dateTime
              fromDateTime
              toDateTime
          }
        }
      }
    }
}
`;

export interface TaskListQueryResponse {
  tasks: Page<Task>;
  loading: boolean;
}


