
import { BrowserModule } from '@angular/platform-browser';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeKeycloak } from './init/keycloak-init.factory';

import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { CoreModuleModule, GrowthBookService, initializeGrowthBook } from '@nexato/nx-core-module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApolloModule } from 'apollo-angular';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { GraphQLModule } from './graphql.module';
import { AuthInterceptorService } from './guard/auth.service';
import { RentModule } from './rent-module/rent.module';
import { createTranslateLoader, initializeTranslate } from './shared-module/shared/services/translation.service';
import { TourPlanningModule } from './tour-planning/tour-planning.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ApolloModule,
    KeycloakAngularModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModuleModule.forRoot(environment),
    RentModule,
    TourPlanningModule,
    ApmModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader
      }
    })
  ],
  providers: [
    AuthInterceptorService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, GrowthBookService, TranslateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    CurrencyPipe,
    ApmService,
    PrimeNGConfig,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    GrowthBookService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGrowthBook,
      deps: [GrowthBookService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslate,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private primeNGConfig: PrimeNGConfig, service: ApmService) {
    const apm = service.init({
      active: environment.apmActive,
      serviceName: environment.name,
      serverUrl: environment.apmServerUrl,
      distributedTracingOrigins: [environment.backendUri],
      environment: environment.env,
      breakdownMetrics: true,
      serviceVersion: environment.version
    })
    // Set the locale for PrimeNG components
    this.primeNGConfig.setTranslation({
      monthNames: [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
      ],
      monthNamesShort: [
        'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
        'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'
      ],
      dayNames: [
        'Sonntag', 'Montag', 'Dienstag', 'Mittwoch',
        'Donnerstag', 'Freitag', 'Samstag'
      ],
      dayNamesMin: [
        'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'
      ],
      firstDayOfWeek: 1
      // ... Add more translations as needed
    });

  }
}
