
<div>

  <nx-pageTitle-box
    [label]="order?.number"
    [bcRootLvlLabel]="'Aufträge'"
    [bcRootLvlPath]="'/orders'"
    [icon]="Order.getOrderIcon(order?.type?.toString())"
    [iconToolTip]="Order.getOrderType(order?.type?.toString())"
    [menuItems]="pageTitleMenuItems">
    <ng-template nxTemplate="tag">
      <p-tag [value]="Order.getOrderState(order?.state + '')" [severity]="order?.getOrderStateSeverity()"></p-tag>
    </ng-template>
  </nx-pageTitle-box>

  <div class="bodyWrapper flex flex-row gap-5">
    <div class="basis-2/3 mt-[10px] widthFixForTable">
      <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true">

        <p-tabPanel header="Allgemein">
          <ng-container [ngSwitch]="order?.type">
            <app-order-rent-line-items
              [lineItems]="order?.lineItems"
              *ngSwitchCase="'RENTAL'">
            </app-order-rent-line-items>
            <app-order-service-line-items [lineItems]="order?.lineItems"
              *ngSwitchCase="'SERVICE'"></app-order-service-line-items>
          </ng-container>
        </p-tabPanel>

        <p-tabPanel header="Aufgaben ({{tasksCount}})">
          <div class="mt-[1.25rem]">
            <app-order-task-list-view [orderId]="this?.id"></app-order-task-list-view>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Protokolle ({{protocolsCount}})">
          <ng-container [ngSwitch]="order?.type">
            <app-order-rent-protocols-view *ngSwitchCase="'RENTAL'" [order]="order"></app-order-rent-protocols-view>
          </ng-container>
        </p-tabPanel>

        <p-tabPanel header="Kommentare ({{commentsCount}})">
          <app-order-comments-list [order]="order?.id" (updateChild)="childUpdated()" />
        </p-tabPanel> 

        <p-tabPanel header="Dateien ({{order?.files?.length}})">
          <app-order-files [order]="order?.id" (updateChild)="childUpdated()"></app-order-files>
        </p-tabPanel>  

    </p-tabView>

    </div>
    <div class="basis-1/3 mt-[10px]">
      <div class="flex flex-col">
        <div class="basis-full">
          <div [ngSwitch]="order?.type">
            <app-order-rent-details [order]="order" *ngSwitchCase="'RENTAL'"
              [customSections]="customAttributeSections"></app-order-rent-details>
            <app-order-service-details [order]="order" *ngSwitchCase="'SERVICE'"></app-order-service-details>
          </div>
        </div>
      </div>
    </div>
  </div>
