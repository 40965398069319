import { Component, Input, OnInit } from '@angular/core';
import { GrowthBookService, RolesService } from '@nexato/nx-core-module';
import { MenuItem, TooltipOptions } from 'primeng/api';
import { LicenseService } from '../../shared/services/license.service';
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  role: string | undefined;
  count: number;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent
implements OnInit {

  public menuItems: MenuItem[];
  public count: number;
  @Input() isExpanded = true;

  tooltipOptions: TooltipOptions = {
    hideDelay: 150,
    showDelay: 300,
    tooltipEvent: 'hover',
    tooltipPosition: 'bottom'
  };

  constructor(
    public rolesService: RolesService,
    public licenseService: LicenseService,
    public growthBookService: GrowthBookService
  ) {
  }

  ngOnInit(): void {
    let availableMenuItems = [
      {
        routerLink: '/dashboard',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Dashboard',
        type: 'link',
        icon: 'icon-dashboard',
        role: 'nexcore_dashboard_view',
        count: -1,
      },
      {
        routerLink: 'tour-planner',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Tourenplaner',
        type: 'link',
        icon: 'icon-tour',
        role: 'nexcore_tour_view',
        count: -1,
      },
      {
        routerLink: '/assignment',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Disposition',
        type: 'link',
        icon: 'icon-taskplanner',
        role: 'nexcore_scheduling_view',
        count: 0
      },
      {
        routerLink: 'orders',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Aufträge',
        type: 'link',
        icon: 'icon-order',
        role: 'nexcore_order_view',
        count: -1
      }
      , {
        routerLink: '/customers',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Kunden',
        type: 'link',
        icon: 'icon-group',
        role: 'nexcore_contact_view',
        count: -1
      },
      {
        routerLink: '/resources',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Ressourcen',
        type: 'link',
        icon: 'icon-item',
        role: 'nexcore_resource_view',
        count: -1
      },
      {
        routerLink: '/articles',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Artikel',
        type: 'link',
        icon: 'icon-article',
        role: 'nexcore_article_view',
        count: -1
      }
    ];

    if(this.growthBookService.isFeatureEnabled('rentalResourceAssignmentReviewWorkflow')){
      availableMenuItems.splice(3, 0,  {
        routerLink: '/tasks',
        disabled: !this.licenseService?.isLicenceUser(),
        label: 'Meine Aufgaben (beta)',
        type: 'link',
        icon: 'icon-task',
        role: 'nexcore_scheduling_view',
        count: 0
      });
    }

    this.menuItems = availableMenuItems.filter(item => {
      return this.rolesService.hasRole(item.role);
    });
  }


}
