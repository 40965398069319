import { Component, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { TaskAssignment } from 'src/app/rent-module/shared/entities/taskAssignment/taskAssignment';
import { Tour } from 'src/app/tour-planning/shared/entities/tour';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-tour-task-assignment-strategy-view',
  templateUrl: './tour-task-assignment-strategy-view.component.html'
})
export class TourTaskAssignmentStrategyViewComponent {

  public _assignment: TaskAssignment;
  public tour: Tour;
  
  constructor(
    private apollo: Apollo,
    ) {
  }
  @Input() set assignment(assignment: TaskAssignment) {
    console.log(assignment);
    this._assignment = assignment;
    if(this._assignment) {
      this.loadTourDetails();
    }
  };

  loadTourDetails() {
    this.apollo.query<fromGraphQl.TourQuery>({
      query: fromGraphQl.TOUR_QUERY,
      variables: {
        id: this._assignment.assigneeId
      }
    })
    .pipe(take(1))
    .subscribe( result => {
      this.tour = new Tour(result?.data?.tour);
    })
  }

  getResourceLabel(): string {
    if(this.tour?.resource) {
      return this.tour.resource.name + '/' + this.tour.resource.number;
    }
    return "-"
  }
}
