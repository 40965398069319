import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule } from '@nexato/nx-core-module';
import { LightgalleryModule } from 'lightgallery/angular';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { CommentDialogComponent } from './components/comment-dialog/comment-dialog.component';
import { OrderRentDetailsComponent } from './components/order-rent-details/order-rent-details.component';
import { OrderRentHeaderComponent } from './components/order-rent-header/order-rent-header.component';
import { OrderRentLineItemsComponent } from './components/order-rent-line-items/order-rent-line-items.component';
import { OrderRentProtocolsViewComponent } from './components/order-rent-protocols-view/order-rent-protocols-view.component';
import { OrderRentResourceAssignmentProtocolsViewComponent } from './components/order-rent-resourceAssignment-protocols-view/order-rent-resourceAssignment-protocols-view.component';
import { OrderServiceDetailsComponent } from './components/order-service-details/order-service-details.component';
import { OrderServiceHeaderComponent } from './components/order-service-header/order-service-header.component';
import { OrderServiceLineItemsComponent } from './components/order-service-line-items/order-service-line-items.component';
import { OrderTaskListViewComponent } from './components/order-task-list-view/order-task-list-view.component';
import { OrderViewComponent } from './containers/order-view/order-view.component';
import { OrdersListComponent } from './containers/orders-list/orders-list.component';
import { OrderService } from './shared/services/order/order.service';
@NgModule({
  declarations: [
    OrdersListComponent,
    OrderViewComponent,
    OrderTaskListViewComponent,
    OrderRentHeaderComponent,
    OrderServiceHeaderComponent,
    OrderServiceDetailsComponent,
    OrderRentDetailsComponent,
    OrderRentLineItemsComponent,
    OrderServiceLineItemsComponent,
    CommentDialogComponent,
    OrderRentProtocolsViewComponent,
    OrderRentResourceAssignmentProtocolsViewComponent
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    SharedModule,
    CoreModuleModule,
    ReactiveFormsModule,
    FormsModule,
    LightgalleryModule,
    ClipboardModule,
    TabViewModule,
    TableModule,
    DropdownModule,
    TagModule,
    MultiSelectModule,
    AccordionModule,
    PaginatorModule
  ],
  providers: [
    OrderService,
  ],
  exports: [
    OrdersListComponent,
    OrderViewComponent,
    OrderTaskListViewComponent,
    OrderRentResourceAssignmentProtocolsViewComponent
  ]
})
export class OrderModule { }
