import { Component, Input } from '@angular/core';
import { TaskAssignment } from 'src/app/rent-module/shared/entities/taskAssignment/taskAssignment';

@Component({
  selector: 'app-task-assignment-strategy-view',
  templateUrl: './task-assignment-strategy-view.component.html',
  styleUrls: ['./task-assignment-strategy-view.component.scss']
})
export class TaskAssignmentStrategyViewComponent {

  @Input() public assignment: TaskAssignment;

  constructor() {
  }
  
}
