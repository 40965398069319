
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApmService } from '@elastic/apm-rum-angular';
import { ExpandService, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { KeycloakService } from 'keycloak-angular';
import { MenuItem, TooltipOptions } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from '../../shared/entities/notification/notification';
import { LicenseService } from '../../shared/services/license.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {

  public unreadNotifications$: Observable<Notification[]>;
  
  public familyName: string;
  public givenName: string;
  public userId: string;
  public isloading = false;
  public isLoadingSubscription: Subscription;
  public theme = 'standard';
  public logo = '/assets/standard_Logo.svg';
  public icon = '/assets/standard_Logo-Icon.svg';
  public tenantId: string;
  public tenantName: string;
  public pageTitle: string;
  public pageSubTitle: string;
  public isExpandedAll = false;
  public fullHeight = false;
  public versionNumber = environment.version;
  public helpMeuItems: MenuItem[];
  public mainMenuItems: MenuItem[];

  settingsRoles = ['framework_settings_manage',
    'framework_tenants_manage_own',
    'nexcore_location_view',
    'framework_users_manage',
    'framework_mobileDevice_view',
    'framework_groups_manage',
    'framework_groups_manage',
    'framework_licence_view',
    'framework_forms_manage',
    'framework_templates_manage',
    'framework_developer_view',
  'framework_tenants_manage']

  tooltipOptions: TooltipOptions = {
    hideDelay: 150,
    showDelay: 300,
    tooltipEvent: 'hover',
    tooltipPosition: 'bottom'
  };

  separator
  isExpanded = true;

  notificationsSubscription: Subscription;

  constructor(
    private keycloakAngular: KeycloakService,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    public rolesService: RolesService,
    private renderer: Renderer2,
    public licenseService: LicenseService,
    public expandService: ExpandService,
    private apollo: Apollo,
    private notificationService: NotificationService,
    private apmService: ApmService
  ) {
    let key = 'family_name';
    this.familyName = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'given_name';
    this.givenName = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'page_title';
    this.pageTitle = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'page_subtitle';
    this.pageSubTitle = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'tenant_id';
    this.tenantId = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'id';
    this.userId = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('tour-planner')) {
          this.fullHeight = true;
        } else {
          this.fullHeight = false
        }
      }
    });
    this.unreadNotifications$ = this.notificationService.unreadNotifications$;
    this.helpMeuItems = this.getHelpMenuItems();
    this.mainMenuItems = this.getMainMenuItems();
    if(this.apmService) {
      this.apmService?.apm?.setUserContext({
        id: this.userId
      })
      this.apmService?.apm?.addLabels({
        tenantId: this.tenantId
      })
    }
  }

  ngOnInit(): void {
    this.renderer.removeClass(document.body, this.theme);
    let key = 'theme';
    this.theme = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : 'standard';
    if (this.theme === undefined) {
      this.theme = 'standard';
    }
    this.theme = 'standard';
    this.renderer.addClass(document.body, this.theme);
    this.logo = '/assets/' + this.theme + '_Logo.svg'; // /assets/NXD_PZ-Nuremberg_Logo.svg
    this.isLoadingSubscription =
      this.loadingService?.isLoadingSubject?.subscribe((isloading: boolean) => {
        this.isloading = isloading;
        this.cdr?.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.isLoadingSubscription?.unsubscribe();
    this.notificationsSubscription?.unsubscribe();
  }

  goTo(path: any): void {
    // console.log(path);
    this.router.navigate(path);
  }

  logout(): void {
    this.keycloakAngular.logout();
    // this.keycloakAngular.logout("http://localhost:4200/");
  }

  expandAll() {
    const containers = document.querySelectorAll('.expandedContainer'); // Select all containers with the class 'expandContainer'
    containers.forEach(container => {
      container.classList.toggle('collapseContainer'); // Toggle the 'expandedContainer' class on each container
    });
    this.isExpandedAll = this.isExpandedAll? false: true;
    this.expandService?.setExpandState(this.isExpandedAll);
  }

  getHeight(): string {
    return 'calc(100% - 96px)';
  }

  getMainMenuItems(): MenuItem[] {
    let menuItems = [];

    if(this.settingsRoles.some(role => this.rolesService?.hasRole(role))) {
      menuItems.push({
        label: 'Einstellungen',
        disabled: !this.licenseService?.isLicenceUser(),
        icon: 'icon-settings',
        command: () => {
          this.goTo(['/settings']);
        }
      });

      menuItems.push({
        label: 'Profil',
        disabled: !this.licenseService?.isLicenceUser(),
        icon: 'icon-person',
        command: () => {
          this.goTo(['/profile']);
        }
      });

      menuItems.push({
        separator: true,
      })
    }

    menuItems.push({
      label: 'Logout',
      icon: 'icon-logout',
      command: () => {
        this.logout();
      }
    });
    return menuItems;
  }

  getHelpMenuItems(): MenuItem[] {
    let menuItems = [];

    menuItems.push({
      label: 'Hilfe-Center',
      command: () => {
        window.open('https://nexato.crunch.help/de', '_blank');
      }
    }); 

    menuItems.push({
      label: 'Produkt-Roadmap',
      command: () => {
        window.open('https://app.loopedin.io/nexcore#/roadmap', '_blank');
      }
    });

    menuItems.push({
      label: 'Ideen-Board',
      command: () => {
        window.open('https://app.loopedin.io/nexcore#/ideas', '_blank');
      }
    });

    // menuItems.push({
    //   label: 'Neuigkeiten',
    //   command: () => {
    //     window.open('https://app.loopedin.io/nexcore#/updates', '_blank');
    //   }
    // });

    menuItems.push({
      label: 'Systemstatus/Wartungsarbeiten',
      command: () => {
        window.open('https://nexato.crunch.help/de/systemhinweise', '_blank');
      }
    });
    return menuItems;
  }

}
