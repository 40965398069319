<ng-container [formGroup]="form" *ngIf="form">
  <ng-container *ngFor="let setting of this.settings; let isLast = last;; let isFirst = first;">
    <nx-headline-box
      [ngClass]="{'mt-2': !isFirst}"
      [label]="setting.label">
      <ng-template nxTemplate="tag">
        <span class="text-[0.75rem] relative top-1">{{setting?.description}}</span>
      </ng-template>
    </nx-headline-box>
    <ng-container *ngFor="let field of setting.settingDescriptions; let i=index;" class="pb-5">
      <ng-container *ngIf="(locationId && field?.allowLocationOverride) || !locationId">
      <nx-default-box 
        class="bg-white border-b border-gray">
      <ng-template nxTemplate="content">

          <div class="flex flex-row">
            <div class="grow flex flex-col">
              <div class="">
                {{field.label}}
              </div>
              <div class="text-base text-[#848488] font-light leading-3">
                {{field?.description}}
              </div>
            </div>
            <div 
              [ngSwitch]="true"
              class="min-w-96 flex items-center justify-end">

                <div *ngSwitchCase="field.type === 'Boolean'">
                  <p-inputSwitch 
                    formControlName={{field.key}}>
                  </p-inputSwitch>
                </div>

                <div *ngSwitchCase="field.type === 'String' || field.type === 'Integer'" class="flex flex-row w-full">
                  <input class="invers right w-full rounded-md text" type="text" pInputText formControlName={{field.key}} />
                  <nx-default-button
                    (click)="reset(field)"
                    tooltip="Auf Standardwert zurücksetzen"
                    [disabled]="!(form.controls[field?.key].value !== field.defaultValue && form.enabled)"
                    class="flex justify-end ml-2"
                    [icon]="'icon-return'">
                  </nx-default-button>
                </div>

                <ng-container *ngSwitchCase="field.type === 'TaskTypeColor'">
                  <nx-task-color-picker
                    class="w-full"
                    [field]="field"
                    formControlName={{field.key}}>
                  </nx-task-color-picker>
                </ng-container> 

                <div *ngSwitchCase="field.type === 'File'" class="flex flex-row">
                  <div class="basis-1/2 content-center">
                    <img *ngIf="field?.currentValue?.file" src="{{fileApi}}/view/{{field.currentValue?.file?.fullPath}}?width=69&height=48" class="img-responsive">
                    <span *ngIf="!field?.currentValue?.file" class="grey">Kein Logo vorhanden</span>
                  </div>
                  <div class="basis-1/2 flex justify-end">
                    <app-nx-button *ngIf="field?.currentValue?.file" (click)="form.enabled? confirmDialog(field): $event.stopPropagation()" toolTip="Logo löschen" icon="icon icon-trash" [disabled]="!form.enabled">
                    </app-nx-button>
                    <app-nx-button (click)="form.enabled? fileInput.click(): $event.stopPropagation()" toolTip="Datei hochladen" icon="icon icon-document-upload" [disabled]="!form.enabled">
                    </app-nx-button>
                    <input #fileInput type="file" style="display:none;" (change)="fileChangeEvent($event, field)" [accept]="field?.settings?.fileTypes"/>
                    <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>
                  </div>
                </div>

                <ng-container *ngSwitchCase="field.type === 'Duration'">
                  <!-- <input class="invers right w-full rounded-md text" type="text" pInputText formControlName={{field.key}} />
                  <nx-default-button
                    (click)="reset(field)"
                    tooltip="Auf Standardwert zurücksetzen"
                    [disabled]="!(form.controls[field?.key].value !== field.defaultValue && form.enabled)"
                    class="flex justify-end ml-2"
                    [icon]="'icon-return'">
                  </nx-default-button> -->
                  <nx-duration-picker
                    class="w-full"
                    [field]="field"
                    formControlName={{field.key}}>
                  </nx-duration-picker>
                </ng-container> 

            </div>
            
          </div>
        
      </ng-template>
      </nx-default-box>
      </ng-container>
    </ng-container>

  
  </ng-container>
</ng-container>
