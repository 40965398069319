import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { Order } from 'src/app/order-module/shared/entities/order/order';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-complete-task-view',
  templateUrl: './complete-task-view.component.html',
  styleUrls: ['./complete-task-view.component.scss']
})
export class CompleteTaskViewComponent {

  public Order = Order;

  _task: Task;
  @Input() set task(task: Task) {
    this._task = task;
    if(task && task.id) {
      // we need more information about the task
      this.loadTask(task);
    }
  }
  @Output() refetch = new EventEmitter();

  constructor(
    private apollo: Apollo,
    private changeDetectorRef: ChangeDetectorRef,
  ) {

  }

  loadTask(task: Task) {
    this.apollo.query<fromGraphQl.CompleteTaskQuery>({
      query: fromGraphQl.COMPLETE_TASK_QUERY,
      variables: {
        id: task.id
      },
      fetchPolicy: 'network-only',
    })
    .pipe(take(1))
    .subscribe( result => {
      console.log(result?.data?.task);
      this._task = new Task(result?.data?.task);
    })
  }

  getHeaderLabel(): string {
    if(this._task?.formControlTemplate?.settings?.label) {
      return this._task.formControlTemplate.settings.label;
    }
    return "-"
  }

  completeTask(task: Task) {
    this.apollo.mutate({
      mutation: fromGraphQl.COMPLETE_TASK_MUTATION,
      variables: {
        taskId: task.id,
      },
    })
    .pipe(take(1))
    .subscribe( result => {
      this.refetch.emit();
    });
  }
  
  
}
