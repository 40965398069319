<!-- <div class="flex flex-col" [formGroup]="colorsForm">

</div> -->

<div class="flex flex-row">

  <div class="w-full">
    <input 
      class="invers h-[28px] right w-full rounded-md text" 
      type="text" 
      pInputText 
      [formControl]=durationFormControl />
    <small 
      *ngIf="getErrorMessage()" 
      class="error text-red-500">{{ getErrorMessage() }}</small>
  </div>

  <!--  -->
  <nx-default-button
    (click)="reset()"
    tooltip="Auf Standardwert zurücksetzen"
    [disabled]="durationFormControl?.disabled"
    class="flex justify-end ml-2"
    [icon]="'icon-return'">
  </nx-default-button>
</div>
